<template>
  <transition name="modal-fade">
    <div class="viorayio_scratch_form_popup">
      <div class="viorayio_scratch_form_body tnc_popup" v-if="isTncModalVisible" v-bind:style="popupStyle.cardStyle">
        <div class="viorayio_form_header">
          <h4 v-bind:style="popupStyle.headerStyle">{{tnc.title}}</h4>
          <div
            @click="closeTNCModal"
            class="viorayio_modal_close"
            v-bind:style="`color: ${campaignData.popup_close_icon_color || '#000000'}`"
          >
            <i class="fa fa-times" />
          </div>
        </div>
        <div class="viorayio_tnc_body" v-bind:style="popupStyle.textStyle">
          <div v-html="tnc.value"></div>
        </div>
      </div>
      <div v-else class="viorayio_scratch_form_body" v-bind:style="popupStyle.cardStyle">
        <div class="viorayio_form_header">
          <h4 v-bind:style="popupStyle.headerStyle">{{popupTitle}}</h4>
          <h6 v-if="popupSubText" v-bind:style="popupStyle.textStyle">{{popupSubText}}</h6>
          <div
            @click="close"
            class="viorayio_modal_close"
            v-bind:style="`color: ${campaignData.popup_close_icon_color || '#000000'}`"
          >
            <i class="fa fa-times" />
          </div>
        </div>
        <a-form :model="form" @submit="onSubmit">
          <div
            :key="data.name"
            :class="['viorayio_form_fields', getClass(data.name), data.class]"
            v-for="data of popupopFormData"
          >
            <a-input
              required
              :type="data.type"
              :placeholder="data.placeholder"
              v-model:value="form[`${data.name}`]"
              class="viorayio_formcontrol"
              :number="data.type == 'number'"
              v-bind:style="inputStyle"
            />
          </div>
          <div class="viorayio_form_fields">
            <div v-if="tnc.label" class="form-check">
              <a-checkbox
                id="TNCCheck"
                name="condition"
                v-model:checked="condition"
              />
              <a @click="showTNCModal" class="form-check-label" v-bind:style="tcTextStyle">
                {{tnc.label}}
              </a>
            </div>
            <div class="error-text" v-if="tncError">{{tncError}}</div>
          </div>
          <div class="viorayio_form_fields">
            <div class="viorayio_form_seprator" v-bind:style="inputStyle" />
            <button type="submit" class="viorayio_common_btn" v-bind:style="popupStyle.buttonStyle">{{popupBtnTxt}}</button>
          </div>
        </a-form>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from "lodash";
export default {
  name: "FormModal",
  data() {
    return {
      form: {},
      isTncModalVisible: false,
      condition: false,
      tncError: "",
      inputStyle: {},
    };
  },
  props: {
    popupopFormData: Array,
    tnc: Object,
    popupTitle: {
      default: "",
      type: String,
    },
    popupSubText: {
      default: "",
      type: String,
    },
    popupBtnTxt: {
      default: "",
      type: String,
    },
  },
  computed : {
    popupStyle: function() {
      return this.$store.getters.popupStyle;
    },
    tcTextStyle: function() {
      return this.$store.getters.tcTextStyle;
    },
    campaignData: function() {
      return this.$store.getters.campaignData;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onSubmit(e) {
      e.preventDefault();
      if (!this.condition && this.tnc.label) {
        this.tncError = this.tnc.warning
        return null;
      }
      this.$emit("onSubmit", this.form);
    },
    closeTNCModal() {
      this.isTncModalVisible = false;
    },
    showTNCModal() {
      this.isTncModalVisible = true;
    },
    getClass(name) {
      if (name == "first_name" && _.findIndex(this.popupopFormData, (n) => n.name == 'last_name') > -1) {
        return "fname_style";
      } else if (name == "last_name" && _.findIndex(this.popupopFormData, (n) => n.name == 'first_name') > -1) {
        return "lname_style";
      }
      return "";
    },
  },
  created() {
    const form = {};
    this.popupopFormData.forEach(e => {
      form[e.name] = "";
    });
    this.form = form;
    this.inputStyle = {
      borderColor: this.campaignData?.popup_input_border_color || '#e5e5e5',
      fontSize: this.campaignData?.popup_input_border_color || 14,
    };
  }
}
</script>