<template>
  <transition name="modal-fade">
    <div class="viorayio_scratch_form_popup price_popup" style="display: none">
      <div
        class="viorayio_scratch_form_body tnc_popup" v-bind:style="priceStyle.priceCardStyle"
      >
        <div class="viorayio_form_header">
          <h4 v-bind:style="priceStyle.priceHeaderStyle">{{campaignData.prize_block_title}}</h4>
          <div @click="closeModal" class="viorayio_modal_close">
            <i class="fa fa-times" />
          </div>
        </div>
        <div class="viorayio_tnc_body prize_body">
          <table style="width: 100%">
              <tr v-for="plist in pricelist" :key="plist.encrypt_id" >
                <td v-if="plist.encrypt_id != ''">
                  <div class="alt_prize_img">
                      <img class="" alt="Card Front Image" :src="plist.prize_image"/>
                      <div v-bind:style="priceStyle.priceTagStyle" class="prize_tag">
                        <span v-if="plist.prize_badge_text" v-bind:style="priceStyle.priceTagTitleStyle">{{ plist.prize_badge_text }}</span>
                        {{plist.position == 1 ? plist.currency : ""}}{{plist.prize_value}}{{plist.position == 0 ? plist.currency : ""}}
                      </div>
                  </div>
                </td>
                <td v-if="plist.encrypt_id != ''">
                    <div v-bind:style="priceStyle.priceTextStyle" class="prize_title">{{plist.prize}}</div>
                    <div v-bind:style="priceStyle.priceDescriptionStyle" class="prize_desc">{{plist.prize_description}}</div>
                </td>
              </tr>
          </table>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from "lodash";
export default {
  name: "PriceModal",
  data() {
    return {
      form: {},
      condition: false,
      tncError: "",
      inputStyle: {},
      pricelist: [],
    };
  },
  props: {
    popupopFormData: Array,
    priceData: Object,
    popupTitle: {
      default: "",
      type: String,
    },
    popupSubText: {
      default: "",
      type: String,
    },
    popupBtnTxt: {
      default: "",
      type: String,
    },
  },
  computed: {
    priceStyle: function() {
      return this.$store.getters.priceStyle;
    },
    campaignData: function () {
      return this.$store.getters.campaignData;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  created() {
    this.pricelist = this.priceData.pdata || [];
  },
};

</script>
