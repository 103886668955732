<template>
  <transition name="modal-fade">
    <div class="viorayio_scratch_form_popup cookie_popup" style="display: none">
      <div class="viorayio_scratch_form_body cookie_popup_body" v-bind:style="cookieStyle.cookiePopupCardStyle">
        <div class="viorayio_form_header" v-if="campaignData.cookie_logo == ''">
          <img
            :src="require(`@/assets/img/Gamii-icon.png`)"
            alt="logo" align="right" style="max-height: 50px;"
          />
        </div>
        <div v-else>
          <img
            :src="campaignData.cookie_logo"
            alt="logo" align="right" style="max-height: 50px;"
          />
        </div>
        <div class="cookie_popup_content">
          <div v-html="campaignData.cookie_content"></div>
        </div>
        <div class="cookie_popup_footer">
          <button @click="closeModal" v-bind:style="cookieStyle.cookieCancelButtonStyle" class="cookie_popup_btn cookie_popup_cancel_btn">{{campaignData.cookie_cancel_button_text}}</button>
          <button @click="AcceptCookies()" v-bind:style="cookieStyle.cookieAcceptButtonStyle" class="cookie_popup_btn cookie_popup_accept_btn">{{campaignData.cookie_accept_button_text}}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CookieModal",
  data() {
    return {};
  },
  props: {
    popupData: Object,
  },
  computed: {
    campaignData: function () {
      return this.$store.getters.campaignData;
    },
    cookieStyle: function() {
      return this.$store.getters.cookieStyle;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    AcceptCookies() {
      document.cookie = "campaign_cookie=123456";
      this.closeModal();
    }
  },
  created() {},
};
</script>
